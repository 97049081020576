import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"

const Woodhouse = ({ data }) => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            ДЕРЕВЯННОЕ СТРОИТЕЛЬСТВО
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-2">
        {data.allContentfulWoodhouse.nodes.map(wood => (
          <div key={wood.id}>
            <div className="w-full flex flex-col mb-6 md:flex-col xl:flex-col">
              <div className="flex p-2 justify-center items-center">
                <Img
                  fluid={wood.image.fluid}
                  style={{
                    width: `100%`,
                    height: `20rem`,
                  }}
                  alt={wood.title}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="px-2">
                <h3
                  className="mt-4 text-2xl md:text-3xl lg:text-3xl xl:text-3xl"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  {wood.title}
                </h3>
                <div
                  className="flex justify-between px-8 mt-4 text-sm md:text-sm lg:text-sm xl:text-sm"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  <p>
                    Размер:<br></br> {wood.size}
                  </p>
                  <p>
                    Цена: <br></br> {wood.price}
                  </p>
                  <p>
                    Площадь: <br></br> {wood.square}
                  </p>
                </div>
                <p
                  className="mt-4 text-sm md:text-sm lg:text-sm xl:text-sm"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  {documentToReactComponents(wood.description.json)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Woodhouse

export const PageQuery = graphql`
  query Woodhouse {
    allContentfulWoodhouse {
      nodes {
        id
        image {
          fluid(maxWidth: 600, quality: 35) {
            ...GatsbyContentfulFluid
          }
        }
        price
        square
        title
        size
        description {
          json
        }
      }
    }
  }
`
